/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectResourceTypeEnum } from './projectResourceTypeEnum';
import { ProjectResource } from './projectResource';
import { FactorProjectResourceAllOf } from './factorProjectResourceAllOf';


/**
 * Project resource assigned to factor
 */
import { ValidateUtils } from 'app/shared/utils';

export class FactorProjectResource { 
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier?: string;
    resourceType?: ProjectResourceTypeEnum;
    /**
     * Factor identifier
     */
    identifier?: string;
    /**
     * Resource first name (or group name)
     */
    firstName?: string;
    /**
     * Resource last name
     */
    lastName?: string;
    /**
     * Resource email
     */
    email?: string | null;
    /**
     * Resource organization
     */
    organizationName?: string;
    /**
     * Resource position
     */
    position?: string;
    /**
     * Resource location
     */
    location?: string;
    /**
     * Resource daily rate
     */
    dailyRate?: number | null;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    comments?: string;
    /**
     * Flag indicating whether Resource assigned has work units (if so, cannot be removed)
     */
    readonly hasWorkUnits: boolean;

    constructor(item?: Partial<FactorProjectResource>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            resourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, 36, 36);

                    return result;
                }
            },
            resourceType: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceType : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            identifier: {
                
                minLength: 0,
                maxLength: 255,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.identifier : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, 0, 255);

                    return result;
                }
            },
            firstName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.firstName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            lastName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.lastName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            email: {
                type: 'email',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.email : itemOrValue;

                    if (itemOrValue?.email != null) {
                        if (itemOrValue.email === '') {
                            itemOrValue.email = undefined;
                        } else if (['int32', 'int64'].indexOf('email') >= 0) {
                            itemOrValue.email = parseInt(itemOrValue.email.toString(), 10);
                        } else if (['float'].indexOf('email') >= 0) {
                            itemOrValue.email = parseFloat(itemOrValue.email.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'email');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            organizationName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.organizationName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            position: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.position : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            location: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.location : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            dailyRate: {
                type: 'float',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.dailyRate : itemOrValue;

                    if (itemOrValue?.dailyRate != null) {
                        if (itemOrValue.dailyRate === '') {
                            itemOrValue.dailyRate = undefined;
                        } else if (['int32', 'int64'].indexOf('float') >= 0) {
                            itemOrValue.dailyRate = parseInt(itemOrValue.dailyRate.toString(), 10);
                        } else if (['float'].indexOf('float') >= 0) {
                            itemOrValue.dailyRate = parseFloat(itemOrValue.dailyRate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'float');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            comments: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.comments : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            hasWorkUnits: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.hasWorkUnits : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: FactorProjectResource.isValid,
        };
    }

    static isValid(obj: FactorProjectResource): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = FactorProjectResource.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.resourceIdentifier.isValid(item);
            result = result && validationObject.resourceType.isValid(item);
            result = result && validationObject.identifier.isValid(item);
            result = result && validationObject.firstName.isValid(item);
            result = result && validationObject.lastName.isValid(item);
            result = result && validationObject.email.isValid(item);
            result = result && validationObject.organizationName.isValid(item);
            result = result && validationObject.position.isValid(item);
            result = result && validationObject.location.isValid(item);
            result = result && validationObject.dailyRate.isValid(item);
            result = result && validationObject.comments.isValid(item);
            result = result && validationObject.hasWorkUnits.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

