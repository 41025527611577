/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProgramSummaryAllOf } from './programSummaryAllOf';
import { ProgramSummaryUpdate } from './programSummaryUpdate';


/**
 * Program summary info
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProgramSummary { 
    /**
     * Program name (must be globally unique)
     */
    name?: string;
    /**
     * Program short name
     */
    shortName?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    description?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    tagline?: string;
    /**
     * Flag indicating whether Program is private (or public)
     */
    isPrivate?: boolean;
    /**
     * Miradi object resource identifier
     */
    contactResourceIdentifier?: string;
    /**
     * First name for Contact
     */
    readonly contactFirstName: string;
    /**
     * Last name for Contact
     */
    readonly contactLastName: string;
    /**
     * Email for Contact
     */
    readonly contactEmail: string;

    constructor(item?: Partial<ProgramSummary>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            name: {
                
                minLength: 1,
                maxLength: 200,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, 1, 200);

                    return result;
                }
            },
            shortName: {
                
                minLength: 1,
                maxLength: 50,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.shortName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, 1, 50);

                    return result;
                }
            },
            description: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.description : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            tagline: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.tagline : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isPrivate: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.isPrivate : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            contactResourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.contactResourceIdentifier : itemOrValue;

                    if (itemOrValue?.contactResourceIdentifier != null) {
                        if (itemOrValue.contactResourceIdentifier === '') {
                            itemOrValue.contactResourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.contactResourceIdentifier = parseInt(itemOrValue.contactResourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.contactResourceIdentifier = parseFloat(itemOrValue.contactResourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, 36, 36);

                    return result;
                }
            },
            contactFirstName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.contactFirstName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            contactLastName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.contactLastName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            contactEmail: {
                type: 'email',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.contactEmail : itemOrValue;

                    if (itemOrValue?.contactEmail != null) {
                        if (itemOrValue.contactEmail === '') {
                            itemOrValue.contactEmail = undefined;
                        } else if (['int32', 'int64'].indexOf('email') >= 0) {
                            itemOrValue.contactEmail = parseInt(itemOrValue.contactEmail.toString(), 10);
                        } else if (['float'].indexOf('email') >= 0) {
                            itemOrValue.contactEmail = parseFloat(itemOrValue.contactEmail.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'email');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: ProgramSummary.isValid,
        };
    }

    static isValid(obj: ProgramSummary): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProgramSummary.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.shortName.isValid(item);
            result = result && validationObject.description.isValid(item);
            result = result && validationObject.tagline.isValid(item);
            result = result && validationObject.isPrivate.isValid(item);
            result = result && validationObject.contactResourceIdentifier.isValid(item);
            result = result && validationObject.contactFirstName.isValid(item);
            result = result && validationObject.contactLastName.isValid(item);
            result = result && validationObject.contactEmail.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

