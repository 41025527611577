/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DiagramType } from './diagramType';


/**
 * Project diagram image
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectDiagramImage { 
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier?: string;
    diagramType?: DiagramType;
    /**
     * Factor identifier
     */
    identifier?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    name?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    details?: string;
    /**
     * Url for full-size image
     */
    readonly imageFullUrl?: string;
    /**
     * Height (pixels) for full-size image
     */
    readonly imageFullHeight?: number;
    /**
     * Width (pixels) for full-size image
     */
    readonly imageFullWidth?: number;
    /**
     * Url for download of image
     */
    readonly imageDownloadUrl?: string;
    /**
     * Image file size
     */
    readonly imageFileSize?: string;

    constructor(item?: Partial<ProjectDiagramImage>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            resourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, 36, 36);

                    return result;
                }
            },
            diagramType: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.diagramType : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            identifier: {
                
                minLength: 0,
                maxLength: 255,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.identifier : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, 0, 255);

                    return result;
                }
            },
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            details: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.details : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            imageFullUrl: {
                type: 'uri',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.imageFullUrl : itemOrValue;

                    if (itemOrValue?.imageFullUrl != null) {
                        if (itemOrValue.imageFullUrl === '') {
                            itemOrValue.imageFullUrl = undefined;
                        } else if (['int32', 'int64'].indexOf('uri') >= 0) {
                            itemOrValue.imageFullUrl = parseInt(itemOrValue.imageFullUrl.toString(), 10);
                        } else if (['float'].indexOf('uri') >= 0) {
                            itemOrValue.imageFullUrl = parseFloat(itemOrValue.imageFullUrl.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uri');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            imageFullHeight: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.imageFullHeight : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            imageFullWidth: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.imageFullWidth : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            imageDownloadUrl: {
                type: 'uri',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.imageDownloadUrl : itemOrValue;

                    if (itemOrValue?.imageDownloadUrl != null) {
                        if (itemOrValue.imageDownloadUrl === '') {
                            itemOrValue.imageDownloadUrl = undefined;
                        } else if (['int32', 'int64'].indexOf('uri') >= 0) {
                            itemOrValue.imageDownloadUrl = parseInt(itemOrValue.imageDownloadUrl.toString(), 10);
                        } else if (['float'].indexOf('uri') >= 0) {
                            itemOrValue.imageDownloadUrl = parseFloat(itemOrValue.imageDownloadUrl.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uri');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            imageFileSize: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.imageFileSize : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: ProjectDiagramImage.isValid,
        };
    }

    static isValid(obj: ProjectDiagramImage): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectDiagramImage.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.resourceIdentifier.isValid(item);
            result = result && validationObject.diagramType.isValid(item);
            result = result && validationObject.identifier.isValid(item);
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.details.isValid(item);
            result = result && validationObject.imageFullUrl.isValid(item);
            result = result && validationObject.imageFullHeight.isValid(item);
            result = result && validationObject.imageFullWidth.isValid(item);
            result = result && validationObject.imageDownloadUrl.isValid(item);
            result = result && validationObject.imageFileSize.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

