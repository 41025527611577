/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IndicatorFutureStatusAllOf } from './indicatorFutureStatusAllOf';
import { ProgressReportStatusEnum } from './progressReportStatusEnum';
import { ProgressReportNew } from './progressReportNew';


/**
 * Project progress report
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProgressReport { 
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    details?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    lessonsLearned?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    nextSteps?: string;
    /**
     * Progress report date
     */
    progressDate?: string;
    status?: ProgressReportStatusEnum;
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier: string;

    constructor(item?: Partial<ProgressReport>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            details: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.details : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            lessonsLearned: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.lessonsLearned : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            nextSteps: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.nextSteps : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            progressDate: {
                type: 'date',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.progressDate : itemOrValue;

                    if (itemOrValue?.progressDate != null) {
                        if (itemOrValue.progressDate === '') {
                            itemOrValue.progressDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date') >= 0) {
                            itemOrValue.progressDate = parseInt(itemOrValue.progressDate.toString(), 10);
                        } else if (['float'].indexOf('date') >= 0) {
                            itemOrValue.progressDate = parseFloat(itemOrValue.progressDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            status: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.status : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            resourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, 36, 36);

                    return result;
                }
            },
            isValid: ProgressReport.isValid,
        };
    }

    static isValid(obj: ProgressReport): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProgressReport.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.details.isValid(item);
            result = result && validationObject.lessonsLearned.isValid(item);
            result = result && validationObject.nextSteps.isValid(item);
            result = result && validationObject.progressDate.isValid(item);
            result = result && validationObject.status.isValid(item);
            result = result && validationObject.resourceIdentifier.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

