/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChartMetric } from './chartMetric';


/**
 * Program / Project metrics (chart data)
 */
import { ValidateUtils } from 'app/shared/utils';

export class ChartData { 
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier?: string;
    /**
     * Chart label
     */
    readonly chartLabel?: string;
    /**
     * Total count
     */
    readonly totalCount?: number;
    /**
     * Total label
     */
    readonly totalLabel?: string;
    /**
     * Metric label
     */
    readonly metricLabel?: string;
    /**
     * List of metrics
     */
    readonly metrics?: Array<ChartMetric>;

    constructor(item?: Partial<ChartData>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            resourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, 36, 36);

                    return result;
                }
            },
            chartLabel: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.chartLabel : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            totalCount: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.totalCount : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            totalLabel: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.totalLabel : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            metricLabel: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.metricLabel : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            metrics: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.metrics : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: ChartData.isValid,
        };
    }

    static isValid(obj: ChartData): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ChartData.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.resourceIdentifier.isValid(item);
            result = result && validationObject.chartLabel.isValid(item);
            result = result && validationObject.totalCount.isValid(item);
            result = result && validationObject.totalLabel.isValid(item);
            result = result && validationObject.metricLabel.isValid(item);
            result = result && validationObject.metrics.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

