/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SimpleScopeRatingEnum } from './simpleScopeRatingEnum';
import { EvidenceConfidenceProjectEnum } from './evidenceConfidenceProjectEnum';
import { ThreatRatingDisplayEnum } from './threatRatingDisplayEnum';
import { SimpleIrreversibilityRatingEnum } from './simpleIrreversibilityRatingEnum';
import { SimpleSeverityRatingEnum } from './simpleSeverityRatingEnum';


/**
 * Target Simple Threat Rating
 */
import { ValidateUtils } from 'app/shared/utils';

export class TargetSimpleThreatRating { 
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier?: string;
    /**
     * Miradi object resource identifier
     */
    threatResourceIdentifier?: string;
    /**
     * Miradi object resource identifier
     */
    targetResourceIdentifier?: string;
    calculatedThreatRating?: ThreatRatingDisplayEnum | null;
    /**
     * Indicates that the target threat rating is not a direct link
     */
    isNotApplicable?: boolean;
    scopeRating?: SimpleScopeRatingEnum;
    irreversibilityRating?: SimpleIrreversibilityRatingEnum;
    severityRating?: SimpleSeverityRatingEnum;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    comments?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    evidenceNotes?: string;
    evidenceConfidence?: EvidenceConfidenceProjectEnum;

    constructor(item?: Partial<TargetSimpleThreatRating>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            resourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, 36, 36);

                    return result;
                }
            },
            threatResourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.threatResourceIdentifier : itemOrValue;

                    if (itemOrValue?.threatResourceIdentifier != null) {
                        if (itemOrValue.threatResourceIdentifier === '') {
                            itemOrValue.threatResourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.threatResourceIdentifier = parseInt(itemOrValue.threatResourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.threatResourceIdentifier = parseFloat(itemOrValue.threatResourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, 36, 36);

                    return result;
                }
            },
            targetResourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.targetResourceIdentifier : itemOrValue;

                    if (itemOrValue?.targetResourceIdentifier != null) {
                        if (itemOrValue.targetResourceIdentifier === '') {
                            itemOrValue.targetResourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.targetResourceIdentifier = parseInt(itemOrValue.targetResourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.targetResourceIdentifier = parseFloat(itemOrValue.targetResourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, 36, 36);

                    return result;
                }
            },
            calculatedThreatRating: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.calculatedThreatRating : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isNotApplicable: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.isNotApplicable : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            scopeRating: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.scopeRating : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            irreversibilityRating: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.irreversibilityRating : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            severityRating: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.severityRating : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            comments: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.comments : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            evidenceNotes: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.evidenceNotes : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            evidenceConfidence: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.evidenceConfidence : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: TargetSimpleThreatRating.isValid,
        };
    }

    static isValid(obj: TargetSimpleThreatRating): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = TargetSimpleThreatRating.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.resourceIdentifier.isValid(item);
            result = result && validationObject.threatResourceIdentifier.isValid(item);
            result = result && validationObject.targetResourceIdentifier.isValid(item);
            result = result && validationObject.calculatedThreatRating.isValid(item);
            result = result && validationObject.isNotApplicable.isValid(item);
            result = result && validationObject.scopeRating.isValid(item);
            result = result && validationObject.irreversibilityRating.isValid(item);
            result = result && validationObject.severityRating.isValid(item);
            result = result && validationObject.comments.isValid(item);
            result = result && validationObject.evidenceNotes.isValid(item);
            result = result && validationObject.evidenceConfidence.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

