/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectKeyValuePair } from './projectKeyValuePair';


/**
 * Program portfolio
 */
import { ValidateUtils } from 'app/shared/utils';

export class Portfolio { 
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier?: string;
    /**
     * Portfolio name
     */
    portfolioName?: string;
    /**
     * Portfolio description
     */
    portfolioDescription?: string;
    /**
     * Portfolio guidance
     */
    portfolioGuidance?: string;
    /**
     * Date portfolio last updated
     */
    readonly lastUpdatedDate?: string;
    /**
     * Resource identifier for User who last updated portfolio (if applicable)
     */
    readonly lastUpdatedByResourceIdentifier?: string;
    /**
     * Full name of User who who last updated portfolio (if applicable)
     */
    readonly lastUpdatedByFullName?: string;
    /**
     * Relevant Projects for Portfolio
     */
    relevantProjectResourceIdentifiers?: Array<ProjectKeyValuePair>;
    /**
     * List of tags for filtering
     */
    filterTags?: Array<string>;

    constructor(item?: Partial<Portfolio>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            resourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, 36, 36);

                    return result;
                }
            },
            portfolioName: {
                
                minLength: 1,
                maxLength: 200,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.portfolioName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, 1, 200);

                    return result;
                }
            },
            portfolioDescription: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.portfolioDescription : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            portfolioGuidance: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.portfolioGuidance : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            lastUpdatedDate: {
                type: 'date-time',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.lastUpdatedDate : itemOrValue;

                    if (itemOrValue?.lastUpdatedDate != null) {
                        if (itemOrValue.lastUpdatedDate === '') {
                            itemOrValue.lastUpdatedDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date-time') >= 0) {
                            itemOrValue.lastUpdatedDate = parseInt(itemOrValue.lastUpdatedDate.toString(), 10);
                        } else if (['float'].indexOf('date-time') >= 0) {
                            itemOrValue.lastUpdatedDate = parseFloat(itemOrValue.lastUpdatedDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date-time');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            lastUpdatedByResourceIdentifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.lastUpdatedByResourceIdentifier : itemOrValue;

                    if (itemOrValue?.lastUpdatedByResourceIdentifier != null) {
                        if (itemOrValue.lastUpdatedByResourceIdentifier === '') {
                            itemOrValue.lastUpdatedByResourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.lastUpdatedByResourceIdentifier = parseInt(itemOrValue.lastUpdatedByResourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.lastUpdatedByResourceIdentifier = parseFloat(itemOrValue.lastUpdatedByResourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            lastUpdatedByFullName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.lastUpdatedByFullName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            relevantProjectResourceIdentifiers: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.relevantProjectResourceIdentifiers : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            filterTags: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.filterTags : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: Portfolio.isValid,
        };
    }

    static isValid(obj: Portfolio): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = Portfolio.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.resourceIdentifier.isValid(item);
            result = result && validationObject.portfolioName.isValid(item);
            result = result && validationObject.portfolioDescription.isValid(item);
            result = result && validationObject.portfolioGuidance.isValid(item);
            result = result && validationObject.lastUpdatedDate.isValid(item);
            result = result && validationObject.lastUpdatedByResourceIdentifier.isValid(item);
            result = result && validationObject.lastUpdatedByFullName.isValid(item);
            result = result && validationObject.relevantProjectResourceIdentifiers.isValid(item);
            result = result && validationObject.filterTags.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

