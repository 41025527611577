/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DiagramFactorLocationType } from './diagramFactorLocationType';


/**
 * Project diagram link
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectDiagramLinkUpdate { 
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    annotation?: string;
    /**
     * Diagram link bend points
     */
    bendPoints?: Array<DiagramFactorLocationType> | null;
    /**
     * Diagram link is bidirectional (cannot be changed via bulk update)
     */
    isBidirectional?: boolean;
    /**
     * Diagram link is uncertain
     */
    isUncertain?: boolean;
    /**
     * Diagram link color
     */
    linkColor?: string | null;

    constructor(item?: Partial<ProjectDiagramLinkUpdate>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            resourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, 36, 36);

                    return result;
                }
            },
            annotation: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.annotation : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            bendPoints: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.bendPoints : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isBidirectional: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.isBidirectional : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isUncertain: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.isUncertain : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            linkColor: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.linkColor : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: ProjectDiagramLinkUpdate.isValid,
        };
    }

    static isValid(obj: ProjectDiagramLinkUpdate): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectDiagramLinkUpdate.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.resourceIdentifier.isValid(item);
            result = result && validationObject.annotation.isValid(item);
            result = result && validationObject.bendPoints.isValid(item);
            result = result && validationObject.isBidirectional.isValid(item);
            result = result && validationObject.isUncertain.isValid(item);
            result = result && validationObject.linkColor.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

