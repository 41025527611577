/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FactorType } from './factorType';
import { EvidenceConfidenceExternalEnum } from './evidenceConfidenceExternalEnum';


/**
 * Goal
 */
import { ValidateUtils } from 'app/shared/utils';

export class Goal { 
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier?: string;
    parentFactorType?: FactorType;
    /**
     * Miradi object resource identifier
     */
    parentFactorResourceIdentifier?: string;
    /**
     * Factor identifier
     */
    identifier?: string;
    /**
     * Goal name
     */
    readonly name?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    details?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    comments?: string;
    evidenceConfidence?: EvidenceConfidenceExternalEnum;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    evidenceNotes?: string;

    constructor(item?: Partial<Goal>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            resourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, 36, 36);

                    return result;
                }
            },
            parentFactorType: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.parentFactorType : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            parentFactorResourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.parentFactorResourceIdentifier : itemOrValue;

                    if (itemOrValue?.parentFactorResourceIdentifier != null) {
                        if (itemOrValue.parentFactorResourceIdentifier === '') {
                            itemOrValue.parentFactorResourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.parentFactorResourceIdentifier = parseInt(itemOrValue.parentFactorResourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.parentFactorResourceIdentifier = parseFloat(itemOrValue.parentFactorResourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, 36, 36);

                    return result;
                }
            },
            identifier: {
                
                minLength: 0,
                maxLength: 255,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.identifier : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, 0, 255);

                    return result;
                }
            },
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            details: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.details : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            comments: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.comments : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            evidenceConfidence: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.evidenceConfidence : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            evidenceNotes: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.evidenceNotes : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: Goal.isValid,
        };
    }

    static isValid(obj: Goal): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = Goal.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.resourceIdentifier.isValid(item);
            result = result && validationObject.parentFactorType.isValid(item);
            result = result && validationObject.parentFactorResourceIdentifier.isValid(item);
            result = result && validationObject.identifier.isValid(item);
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.details.isValid(item);
            result = result && validationObject.comments.isValid(item);
            result = result && validationObject.evidenceConfidence.isValid(item);
            result = result && validationObject.evidenceNotes.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

