/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MapLayer } from './mapLayer';


/**
 * Program map layer
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProgramMapLayer { 
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier?: string;
    /**
     * Map layer name
     */
    name?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    description?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    source?: string;
    /**
     * Flag indicating whether map layer is currently pending import
     */
    readonly isPendingImport?: boolean;
    /**
     * Layer import start datetime
     */
    readonly startImportDatetime?: string | null;
    /**
     * Layer import complete datetime
     */
    readonly endImportDatetime?: string | null;
    /**
     * Map layers (populated by import process)
     */
    mapLayers?: Array<MapLayer>;

    constructor(item?: Partial<ProgramMapLayer>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            resourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, 36, 36);

                    return result;
                }
            },
            name: {
                
                minLength: 0,
                maxLength: 1024,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, 0, 1024);

                    return result;
                }
            },
            description: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.description : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            source: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.source : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isPendingImport: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.isPendingImport : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            startImportDatetime: {
                type: 'date-time',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.startImportDatetime : itemOrValue;

                    if (itemOrValue?.startImportDatetime != null) {
                        if (itemOrValue.startImportDatetime === '') {
                            itemOrValue.startImportDatetime = undefined;
                        } else if (['int32', 'int64'].indexOf('date-time') >= 0) {
                            itemOrValue.startImportDatetime = parseInt(itemOrValue.startImportDatetime.toString(), 10);
                        } else if (['float'].indexOf('date-time') >= 0) {
                            itemOrValue.startImportDatetime = parseFloat(itemOrValue.startImportDatetime.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date-time');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            endImportDatetime: {
                type: 'date-time',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.endImportDatetime : itemOrValue;

                    if (itemOrValue?.endImportDatetime != null) {
                        if (itemOrValue.endImportDatetime === '') {
                            itemOrValue.endImportDatetime = undefined;
                        } else if (['int32', 'int64'].indexOf('date-time') >= 0) {
                            itemOrValue.endImportDatetime = parseInt(itemOrValue.endImportDatetime.toString(), 10);
                        } else if (['float'].indexOf('date-time') >= 0) {
                            itemOrValue.endImportDatetime = parseFloat(itemOrValue.endImportDatetime.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date-time');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            mapLayers: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.mapLayers : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: ProgramMapLayer.isValid,
        };
    }

    static isValid(obj: ProgramMapLayer): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProgramMapLayer.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.resourceIdentifier.isValid(item);
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.description.isValid(item);
            result = result && validationObject.source.isValid(item);
            result = result && validationObject.isPendingImport.isValid(item);
            result = result && validationObject.startImportDatetime.isValid(item);
            result = result && validationObject.endImportDatetime.isValid(item);
            result = result && validationObject.mapLayers.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

