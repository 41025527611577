/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FactorSummaryNew } from './factorSummaryNew';
import { FactorSummaryAllOf } from './factorSummaryAllOf';
import { FactorType } from './factorType';
import { TaxonomyClassification } from './taxonomyClassification';


/**
 * Factor summary info
 */
import { ValidateUtils } from 'app/shared/utils';

export class FactorSummary { 
    /**
     * Factor identifier
     */
    identifier?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    name?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    details?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    comments?: string;
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier: string;
    factorType: FactorType;
    parentFactor?: FactorSummary;
    /**
     * Flag indicating whether comments are editable
     */
    readonly commentsAreEditable: boolean;
    /**
     * Diagram factor conceptual model diagram references
     */
    conceptualModelDiagramResourceIdentifiers?: Array<string>;
    /**
     * Diagram factor results chain diagram references
     */
    resultsChainDiagramResourceIdentifiers?: Array<string>;
    /**
     * Factor taxonomy classifications (if applicable) - only returned for Factor Type lookups
     */
    readonly taxonomyClassifications?: Array<TaxonomyClassification>;

    constructor(item?: Partial<FactorSummary>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            identifier: {
                
                minLength: 0,
                maxLength: 255,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.identifier : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, 0, 255);

                    return result;
                }
            },
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            details: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.details : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            comments: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.comments : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            resourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, 36, 36);

                    return result;
                }
            },
            factorType: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorType : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            parentFactor: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.parentFactor : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            commentsAreEditable: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.commentsAreEditable : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            conceptualModelDiagramResourceIdentifiers: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.conceptualModelDiagramResourceIdentifiers : itemOrValue;

                    if (itemOrValue?.conceptualModelDiagramResourceIdentifiers != null) {
                        if (itemOrValue.conceptualModelDiagramResourceIdentifiers === '') {
                            itemOrValue.conceptualModelDiagramResourceIdentifiers = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.conceptualModelDiagramResourceIdentifiers = parseInt(itemOrValue.conceptualModelDiagramResourceIdentifiers.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.conceptualModelDiagramResourceIdentifiers = parseFloat(itemOrValue.conceptualModelDiagramResourceIdentifiers.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            resultsChainDiagramResourceIdentifiers: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resultsChainDiagramResourceIdentifiers : itemOrValue;

                    if (itemOrValue?.resultsChainDiagramResourceIdentifiers != null) {
                        if (itemOrValue.resultsChainDiagramResourceIdentifiers === '') {
                            itemOrValue.resultsChainDiagramResourceIdentifiers = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resultsChainDiagramResourceIdentifiers = parseInt(itemOrValue.resultsChainDiagramResourceIdentifiers.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resultsChainDiagramResourceIdentifiers = parseFloat(itemOrValue.resultsChainDiagramResourceIdentifiers.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            taxonomyClassifications: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.taxonomyClassifications : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: FactorSummary.isValid,
        };
    }

    static isValid(obj: FactorSummary): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = FactorSummary.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.identifier.isValid(item);
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.details.isValid(item);
            result = result && validationObject.comments.isValid(item);
            result = result && validationObject.resourceIdentifier.isValid(item);
            result = result && validationObject.factorType.isValid(item);
            result = result && validationObject.parentFactor.isValid(item);
            result = result && validationObject.commentsAreEditable.isValid(item);
            result = result && validationObject.conceptualModelDiagramResourceIdentifiers.isValid(item);
            result = result && validationObject.resultsChainDiagramResourceIdentifiers.isValid(item);
            result = result && validationObject.taxonomyClassifications.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

