/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IndicatorRatingSourceEnum } from './indicatorRatingSourceEnum';
import { ViabilityMeasurement } from './viabilityMeasurement';
import { IndicatorPriorityRatingEnum } from './indicatorPriorityRatingEnum';


/**
 * Indicator viability
 */
import { ValidateUtils } from 'app/shared/utils';

export class IndicatorViability { 
    /**
     * Miradi object resource identifier
     */
    identifier?: string;
    /**
     * Indicator name
     */
    readonly name?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    details?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    comments?: string;
    priorityRating?: IndicatorPriorityRatingEnum;
    ratingSource?: IndicatorRatingSourceEnum;
    /**
     * Indicator unit
     */
    readonly unit?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    viabilityRatingsComments?: string;
    /**
     * Indicator has incomplete or invalid Measurements or Future Statuses
     */
    readonly hasIncompleteOrInvalidData?: boolean;
    /**
     * Measurements (valid only, ascending date)
     */
    readonly measurements?: Array<ViabilityMeasurement>;
    /**
     * Future statuses (valid only, ascending date)
     */
    readonly futureStatuses?: Array<ViabilityMeasurement>;

    constructor(item?: Partial<IndicatorViability>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            identifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.identifier : itemOrValue;

                    if (itemOrValue?.identifier != null) {
                        if (itemOrValue.identifier === '') {
                            itemOrValue.identifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.identifier = parseInt(itemOrValue.identifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.identifier = parseFloat(itemOrValue.identifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, 36, 36);

                    return result;
                }
            },
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            details: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.details : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            comments: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.comments : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            priorityRating: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.priorityRating : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            ratingSource: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.ratingSource : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            unit: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.unit : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            viabilityRatingsComments: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.viabilityRatingsComments : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            hasIncompleteOrInvalidData: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.hasIncompleteOrInvalidData : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            measurements: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.measurements : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            futureStatuses: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.futureStatuses : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: IndicatorViability.isValid,
        };
    }

    static isValid(obj: IndicatorViability): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = IndicatorViability.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.identifier.isValid(item);
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.details.isValid(item);
            result = result && validationObject.comments.isValid(item);
            result = result && validationObject.priorityRating.isValid(item);
            result = result && validationObject.ratingSource.isValid(item);
            result = result && validationObject.unit.isValid(item);
            result = result && validationObject.viabilityRatingsComments.isValid(item);
            result = result && validationObject.hasIncompleteOrInvalidData.isValid(item);
            result = result && validationObject.measurements.isValid(item);
            result = result && validationObject.futureStatuses.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

