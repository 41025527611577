/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DiagramFactorColorPalette } from './diagramFactorColorPalette';


/**
 * Diagram color palette
 */
import { ValidateUtils } from 'app/shared/utils';

export class DiagramColorPalette { 
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier?: string;
    /**
     * Palette name
     */
    readonly name?: string;
    /**
     * Palette description
     */
    readonly description?: string;
    /**
     * Flag indicating whether palette is active
     */
    readonly isActive?: boolean;
    /**
     * Diagram factor color palettes
     */
    readonly diagramFactorColors?: Array<DiagramFactorColorPalette>;

    constructor(item?: Partial<DiagramColorPalette>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            resourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, 36, 36);

                    return result;
                }
            },
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            description: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.description : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isActive: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.isActive : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            diagramFactorColors: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.diagramFactorColors : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: DiagramColorPalette.isValid,
        };
    }

    static isValid(obj: DiagramColorPalette): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = DiagramColorPalette.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.resourceIdentifier.isValid(item);
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.description.isValid(item);
            result = result && validationObject.isActive.isValid(item);
            result = result && validationObject.diagramFactorColors.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

